<template>
  <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.764648 0.0675049V19.9686H9.29368V0.0675049H0.764648Z" fill="url(#paint0_linear_151_2144)" />
    <path d="M27.9325 6.16082V15.3578L0.788086 0.0674228L21.7048 0.0148315C21.7048 0.0148315 24.6876 0.348429 26.2472 1.88532C27.8069 3.42221 27.9325 6.16082 27.9325 6.16082Z" fill="url(#paint1_linear_151_2144)" />
    <path d="M9.2959 29.7825V19.872L27.9324 9.51093V23.8641H18.7103L9.2959 29.7825Z" fill="url(#paint2_linear_151_2144)" />
    <defs>
      <linearGradient id="paint0_linear_151_2144" x1="5.02916" y1="0.0675049" x2="5.02916" y2="19.9686" gradientUnits="userSpaceOnUse">
        <stop style="stopColor: rgb(var(--una-primary-600))" />
        <stop offset="1" style="stopColor: rgb(var(--una-primary-500))" />
      </linearGradient>
      <linearGradient id="paint1_linear_151_2144" x1="22.6936" y1="13.2558" x2="17.1023" y2="-1.92851" gradientUnits="userSpaceOnUse">
        <stop style="stopColor: rgb(var(--una-primary-500))" />
        <stop offset="1" style="stopColor: rgb(var(--una-primary-600))" />
      </linearGradient>
      <linearGradient id="paint2_linear_151_2144" x1="14.7005" y1="22.1604" x2="25.7555" y2="15.0478" gradientUnits="userSpaceOnUse">
        <stop style="stopColor: rgb(var(--una-primary-500))" />
        <stop offset="1" style="stopColor: rgb(var(--una-primary-600))" />
      </linearGradient>
    </defs>
  </svg>
</template>
